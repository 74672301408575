import styled from "styled-components";

export const BoxPositionFriend = styled.div`
  width: 100%;
  aspect-ratio: 394/84;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 32px;
  box-sizing: border-box;
  gap: 6px;
`;
export const BoxPositionBtnInvite = styled.div`
  border-radius: 7px;
  background: #109ee6;
  flex: 1;
  display: flex;
  padding-top: 14.672px;
  padding-bottom: 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 8.704px;
  flex: 1 0 0;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  p {
    color: #fff;
    text-align: center;
    text-shadow: 0px 2.224px 0px #000;
    -webkit-text-stroke-width: 0.7412850260734558;
    -webkit-text-stroke-color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%; /* 15px */
  }
  &::after {
    content: "";
    width: 100%;
    height: 9px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: #153e89;
  }
`;
export const BtnCopy = styled.div`
  aspect-ratio: 1;
  display: flex;
  width: 48px;
  padding-top: 12px;
  padding-bottom: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6.376px;
  flex-shrink: 0;
  border-radius: 7px;
  background: #d0d0d0;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  img {
    width: 21px;
  }
  &::after {
    content: "";
    width: 100%;
    height: 9px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: #666;
  }
`;
export const FriendList = styled.div`
  width: 100%;
  height: calc(100% - 31.86vw);
  overflow-y: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  @media only screen and (min-width: 430px) {
    height: calc(100% - 191px);
  }
`;

export const ClaimBox = styled.div`
  width: 92.3%;
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin: 0px auto;
  p {
    color: #d2d2d2;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.26px;
    flex: 1;
  }
`;
export const ClaimBtn = styled.div<{isclaim?:any}>`
  border-radius: 4.448px;
  background: ${({isclaim})=> isclaim ? "#ffb825":"#bababa"} ;
  padding: 9px 18px 11px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  text-shadow: 0px 2.224px 0px #000;
  -webkit-text-stroke-width: 0.7412850260734558;
  -webkit-text-stroke-color: #000;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%; /* 15px */
  white-space: nowrap;

  &::after {
    content: "";
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: ${({isclaim})=> isclaim ? "#ba4e0a":"#444444"} ;
  }
`;

export const BoxAvatarFriend = styled.div<{ rank?: any }>`
  display: flex;
  align-items: center;
  width: 22.16%;
  aspect-ratio: 88/47;
  display: flex;
  border-radius: 13px;
  background: #fff;
  box-shadow: 0px -3px 0px 0px rgba(154, 95, 0, 0.25) inset;
  justify-content: space-between;
  padding: 6px 6px;
  box-sizing: border-box;
  img {
    height: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 1px solid #b8b8b8;
  }
  p {
    color: #fff;
    text-align: center;
    text-shadow: 0px 3px 0px #000;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;
    font-family: PoppinsBold;
    font-size: 4.65vw;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    flex: 1;
  }
  @media only screen and (min-width: 430px) {
    p {
      font-size: 20px;
    }
  }
`;
