import { useLocation } from "react-router-dom";
import {
  BoxCoin,
  BoxUserFloor,
  BoxUserHeader,
  BoxUserName,
  GameHeaderMain,
  GameHeaderWrapper,
  Progess,
} from "./styledv2";
import { useSelector } from "react-redux";
import { convertFormatNumber } from "../../utils/convertFormatNumber";
import { BoxProgessVal } from "../../page/Leaderboard/styled";
import { RULE_GAME } from "../../constant";
import { useDispatch } from "../../store/store";
import { useEffect } from "react";
import { getDataInfoUser } from "../../redux/features/pointSlice";
import { useInitData } from "@tma.js/sdk-react";

const GameHeaderV2 = () => {
  const dataUserTele: any = useInitData();
  const {
    initData: { user },
  } = dataUserTele;
  const dispatch = useDispatch();
  const { getPoints, infoUser } = useSelector((state: any) => state.point);
  useEffect(() => {
    const handleRefestApi = async () => {
      await dispatch(getDataInfoUser(user));
    };
    handleRefestApi();
  }, []);
  useEffect(()=>{
    if(!infoUser){
      window.location.href = "/"
    }
  },[])
  const handleLoadLvprogess = () => {
    switch (infoUser?.level) {
      case 0:
        return (infoUser?.exp / RULE_GAME.EXP.lv1) * 100;
        break;
      case 1:
        return (infoUser?.exp / RULE_GAME.EXP.lv2) * 100;
        break;
      case 2:
        return (infoUser?.exp / RULE_GAME.EXP.lv3) * 100;
        break;
      case 3:
        return (infoUser?.exp / RULE_GAME.EXP.lv4) * 100;
        break;
      case 4:
        return (infoUser?.exp / RULE_GAME.EXP.lv5) * 100;
        break;
      case 5:
        return (infoUser?.exp / RULE_GAME.EXP.lv6) * 100;
        break;
      case 6:
        return (infoUser?.exp / RULE_GAME.EXP.lv7) * 100;
        break;
      case 7:
        return 100;
        break;
    }
  };
  return (
    <GameHeaderWrapper>
      <GameHeaderMain>
        <BoxUserHeader>
          <BoxUserFloor>
            <img
              src={infoUser?.avatar_url || "./static/DefautAvt.png"}
              onError={(e: any) => {
                e.currentTarget.src = "./static/DefautAvt.png";
              }}
            />
            <BoxUserName>
              <p>{infoUser?.fullname}</p>
              <Progess>
                <h6>{infoUser?.level}</h6>
                <BoxProgessVal val={getPoints ? handleLoadLvprogess() : 0}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </BoxProgessVal>
              </Progess>
            </BoxUserName>
          </BoxUserFloor>
        </BoxUserHeader>
        <BoxCoin>
          <img src="./static/ItonLogo.png" />
          <div>
            <p>{getPoints && convertFormatNumber(getPoints)}</p>
          </div>
        </BoxCoin>
      </GameHeaderMain>
    </GameHeaderWrapper>
  );
};

export default GameHeaderV2;
