export const RULE_GAME:any = {
    NUM_CLICK_TO_SEND: 50,
    EXP: {
      lv1: 500000,
      lv2: 1000000,
      lv3: 3000000,
      lv4: 7000000,
      lv5: 15000000,
      lv6: 40000000,
      lv7: 100000000,
    },
    KEEP_CLICK: {
      one_time: {
        target: 150,
        value: 2,
      },
      two_time: {
        target: 300,
        value: 3,
      },
      three_time: {
        target: 600,
        value: 4,
      },
    },
    POINT_BY_LEVEL: {
      lv0: 1,
      lv1: 2,
      lv2: 3,
      lv3: 4,
      lv4: 5,
      lv5: 6,
      lv6: 8,
      lv7: 10,
    },
  };
  