import { useSelector } from "react-redux";
import { BoxElmWallet, BoxToken, LogoToken } from "../styled";


const WalletInfo = () => {
  
  const { listAsset } = useSelector(
    (state: any) => state.point
  );

  return (
    <>
      {listAsset?.map((item: any, index: number) => (
        <BoxElmWallet key={index}>
          <BoxToken>
            <LogoToken>
              <img src={item?.image} alt={item?.symbol} />
            </LogoToken>
            <h5> <span>{item?.symbol}</span>{Number(item?.balance / 1000000000).toFixed(2)}</h5>
          </BoxToken>
        </BoxElmWallet>
      ))}
    </>
  );
};

export default WalletInfo;
