import { useContext, useEffect, useRef, useState } from "react";
import {
  GameWrapper,
  GameContainer,
  GameProgess,
  GamePoins,
  Energy,
  MascotWapper,
  EnergyFloor,
  TextPoints,
} from "./styled";
import { FloorFooter, FloorHeader } from "../../Layout/Dashboard/styled";
import Mascot from "./Mascot";

import toast from "react-hot-toast";
import { motion } from "framer-motion";
import { RULE_GAME } from "../../constant";
import {
  getDataEnergy,
  getDataInfoUser,
  getDataMaxPoint,
  getDataPoint,
  getInfoLevelUser,
  getTimeNextRefill,
  getToastMess,
  setDataExp,
} from "../../redux/features/pointSlice";
import { sha256EncryptAsync } from "../../utils/encryptData";
import { KIBBLE_API } from "../../services/api";
import { useDebouncedCallback } from "use-debounce";
import WebApp from "@twa-dev/sdk";
import { useDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { convertBigNumberToText } from "../../utils/convertBigNumber";
import { convertFormatNumber } from "../../utils/convertFormatNumber";
import { formatUnitTime } from "../../utils/formatUnitTime";
import { useNavigate } from "react-router-dom";
import { useInitData } from "@tma.js/sdk-react";

const Game = () => {
  const {
    // energy,
    getPoints,
    infoUser,
    exps,
  } = useSelector((state: any) => state.point);
  const [pointState, setPointState] = useState(1);
  const [maxTabProcess, setMaxTabProcess] = useState(150);
  const [tapCount, setTapCount] = useState(0);
  const [numberTaps, setNumberTaps] = useState(0);
  const [tapProgress, setTapProgress] = useState(0);
  const [totalTap, setTotalTap] = useState(0);
  const [expsCurrent, setExpsCurrent] = useState(0);
  const [energyLimit, setEnergyLimit] = useState(getPoints);
  const dataUserTele: any = useInitData();
  const {
    initData: { user },
  } = dataUserTele;

  const [pointAppearArray, setPointAppearArray] = useState<any>([]);
  const [lastClickTime, setLastClickTime] = useState(Date.now());
  const [energy, setEnergy] = useState(0);
  useEffect(() => {
    setEnergy(infoUser?.energy);
    setEnergyLimit(infoUser?.energy_limit);
  }, [infoUser?.energy]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDataInfoUser(user));
  }, []);
  const handleClickCoin = async (event: any) => {
    WebApp.HapticFeedback.impactOccurred("light");
    if (!infoUser) return;
    if (navigator.vibrate) {
      navigator.vibrate(20);
    }
    if (energy < 1) {
      dispatch(
        getToastMess({
          message: "Out of energy",
          status: false,
        })
      );
      return;
    }
    const now = new Date().getTime();
    localStorage.setItem("lastTimeClick", `${now}`);
    setTapCount(tapCount + 1);
    setTapProgress(tapProgress + 1);
    setTotalTap(totalTap + 1);
    setNumberTaps(numberTaps + 1);
    setEnergy(energy - 1);
    setExpsCurrent(expsCurrent + pointState * (infoUser?.level + 1));
    dispatch(getDataPoint(getPoints + pointState * (infoUser?.level + 1)));
    dispatch(setDataExp(exps + pointState * (infoUser?.level + 1)));
    const newCoinArray: any = [...pointAppearArray.slice(-50)];
    if (event.touches) {
      for (let i = 0; i < event.touches.length; i++) {
        const touch = event.touches[i];
        const x = touch.clientX;
        const y = touch.clientY;
        newCoinArray.push({
          id: Date.now() + i,
          text: pointState * (infoUser?.level + 1),
          x,
          y,
        });
      }
    } else {
      const x = event.clientX;
      const y = event.clientY;
      newCoinArray.push({ id: Date.now(), text: pointState, x, y });
    }
    setPointAppearArray(newCoinArray);
    setLastClickTime(Date.now());
    debounced();
  };
  const debounced = useDebouncedCallback(
    async () => {
      handleChecKTabToSendClick();
    },
    // delay in ms
    1000
  );
  console.log("pointAppearArray" , pointAppearArray.length);

  const handleChecKTabToSendClick = async () => {
    try {
      if (!exps) return;
      localStorage.setItem("localPoint", getPoints);
      // setTotalTap(0);
      let encryptData = `exps=${expsCurrent}&taps=${numberTaps}`;
      let signatureVerify = await sha256EncryptAsync(encryptData);
      const result = await handleSendDataClick({
        exps: expsCurrent,
        taps: numberTaps,
        signature: signatureVerify,
      });
      if (result?.status === 200) {
        setEnergy(result.data.energy);
      }
      setPointAppearArray([])
      setExpsCurrent(0);
      // setTapCount(0)
      setNumberTaps(0);
      console.log("result===========================================", result);

      dispatch(getDataInfoUser(user));
      // const { data } = await KIBBLE_API.getInfoUser();
    } catch (error) {
      console.log("ERR handleChecKTabToSendClick", error);
    }
  };

  const handleSendDataClick = async (data?: any) => {
    try {
      const res = await KIBBLE_API.sendNumOfClick(data);
      return res;
    } catch (error) {
      console.log("handleSendDataClick error", error);
    }
  };
  const RefInterval: any = useRef();
  useEffect(() => {
    if (RefInterval.current) {
      clearInterval(RefInterval.current);
    }
    const decrementInterval = Date.now() - lastClickTime < 1000 ? 500 : 200;
    RefInterval.current = setInterval(() => {
      setTapCount((prev: any) => Math.max(prev - 5, 0));
      setTapProgress((prev) => Math.max(prev - 5, 0));
    }, decrementInterval);
    return () => {
      clearInterval(RefInterval.current);
    };
  }, [lastClickTime, tapCount]);

  useEffect(() => {
    if (tapCount > RULE_GAME.KEEP_CLICK.one_time.target && pointState === 1) {
      setTapProgress(0);
      setMaxTabProcess(RULE_GAME.KEEP_CLICK.one_time.target);
      setPointState(2);
    } else if (
      tapCount > RULE_GAME.KEEP_CLICK.two_time.target &&
      pointState === 2
    ) {
      setTapProgress(0);
      setMaxTabProcess(
        RULE_GAME.KEEP_CLICK.three_time.target -
          RULE_GAME.KEEP_CLICK.two_time.target
      );
      setPointState(3);
    } else if (
      tapCount > RULE_GAME.KEEP_CLICK.three_time.target &&
      pointState === 3
    ) {
      setTapProgress(0);
      setMaxTabProcess(1000);
      setPointState(4);
    } else if (
      tapCount < RULE_GAME.KEEP_CLICK.one_time.target &&
      pointState === 2
    ) {
      setTapProgress(RULE_GAME.KEEP_CLICK.one_time.target - 1);
      setMaxTabProcess(RULE_GAME.KEEP_CLICK.one_time.target);
      setPointState(1);
    } else if (
      tapCount < RULE_GAME.KEEP_CLICK.two_time.target &&
      pointState === 3
    ) {
      setTapProgress(RULE_GAME.KEEP_CLICK.one_time.target - 1);
      setMaxTabProcess(RULE_GAME.KEEP_CLICK.one_time.target);
      setPointState(2);
    } else if (
      tapCount < RULE_GAME.KEEP_CLICK.three_time.target &&
      pointState === 4
    ) {
      setTapProgress(
        RULE_GAME.KEEP_CLICK.three_time.target -
          RULE_GAME.KEEP_CLICK.two_time.target -
          1
      );
      setMaxTabProcess(
        RULE_GAME.KEEP_CLICK.three_time.target -
          RULE_GAME.KEEP_CLICK.two_time.target
      );
      setPointState(3);
    }
  }, [tapCount]);

  useEffect(() => {
    if (!energyLimit) return;
    const energyInterval = setInterval(() => {
      setEnergy((prevEnergy) => {
        if (prevEnergy < energyLimit) {
          return prevEnergy + 1;
        }
        return prevEnergy;
      });
    }, 1000);

    return () => {
      clearInterval(energyInterval);
    };
  }, [energyLimit]);
  return (
    <GameContainer State={1}>
      <FloorHeader />
      <GameWrapper>
        <GameProgess progess={(tapProgress / maxTabProcess) * 100}>
          <span>{pointState}</span>
        </GameProgess>
        <GamePoins>
          <Energy>
            <img src="./static/EnergyIcon.png" />
            <EnergyFloor>
              <h5>
                {energy}
                <span>/{energyLimit}</span>
              </h5>
            </EnergyFloor>
          </Energy>
        </GamePoins>
        <MascotWapper>
          <Mascot
            handleClickCoin={handleClickCoin}
            pointAppearArray={pointAppearArray}
          />
        </MascotWapper>
      </GameWrapper>
      <FloorFooter />
      {pointAppearArray.map((item: any) => {
        return (
          <TextPoints
            key={item.id}
            style={{
              position: "absolute",
              top: `${item.y}px`,
              left: `${item.x}px`,
              pointerEvents: "none",
              zIndex: 100,
            }}
          >
            +{item.text}
          </TextPoints>
        );
      })}
    </GameContainer>
  );
};

export default Game;
