import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useDebouncedCallback } from "use-debounce";
const Mascot = ({ handleClickCoin }: any) => {
  const [isTouch, setIsTouch] = useState(false);
  const debounced = useDebouncedCallback(async () => {
    setIsTouch(false);
  }, 200);
  return (
    <MasBox
      onTouchStart={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsTouch(true);
        handleClickCoin(e);
        debounced();
      }}
    >
      <div
        className={isTouch ? "div active" : "div"}
        onTouchStart={(e) => {
          e.preventDefault();
        }}
      >
        <Ct>
          <img src="./static/cr.png" />
        </Ct>
        <Cp>
          <img src="./static/cr.png" />
        </Cp>
        <Tp>
          <img src="./static/tr.png" />
        </Tp>
        <TT>
          <img src="./static/tr.png" />
        </TT>
        <Tar>
          <img src="./static/Ta.png" />
        </Tar>
        <Tal>
          <img src="./static/Ta.png" />
        </Tal>
        {/* <img className="mc" src="./static/imgMascot.svg" /> */}
        <motion.img
        whileTap={{ scale: 0.9 }}
        onTouchStart={(e) => {
          handleClickCoin(e);
        }}
        src="./static/imgMascot.svg"
        alt="monkey"
        className="mc"
      />
      </div>
    </MasBox>
  );
};
export default Mascot;

const Ct = styled.div`
  width: 10%;
  position: absolute;
  left: 50%;
  bottom: 3%;
  transition: 0.2s linear;
  img {
    width: 100%;
  }
`;
const Cp = styled.div`
  width: 10%;
  position: absolute;
  left: 50%;
  bottom: 3%;
  transition: 0.2s linear;
  transform: translateX(-100%) rotateY(180deg);
  img {
    width: 100%;
  }
`;
const Tp = styled.div`
  width: 17.5%;
  position: absolute;
  left: 54%;
  bottom: 18%;
  transition: 0.2s linear;
  img {
    width: 100%;
  }
`;
const TT = styled.div`
  width: 17.5%;
  position: absolute;
  left: 45%;
  bottom: 18%;
  transition: 0.2s linear;
  transform: translateX(-100%) rotateY(180deg);
  img {
    width: 100%;
  }
`;
const Tar = styled.div`
  width: 24%;
  position: absolute;
  bottom: 60%;
  left: 69%;
  transition: 0.2s linear;
  z-index: 2;
  img {
    width: 100%;
  }
`;
const Tal = styled.div`
  width: 24%;
  position: absolute;
  bottom: 60%;
  left: 30%;
  transition: 0.2s linear;
  z-index: 2;
  transform: translateX(-100%) rotateY(180deg);
  img {
    width: 100%;
  }
`;
const MasBox = styled.button`
  width: 100%;
  position: relative;
  border: none;
  background: transparent;
  aspect-ratio: 198.3/232.97;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    z-index: 12;
  }
  .mc {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    transition: 0.2s linear;
  }
  transition: 0.2s linear;
  .div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-0px);
    transition: 0.2s linear;
    ${Cp} {
      transform: translateX(-100%) rotateY(180deg);
    }
    ${Ct} {
      transform: translateY(0%) translateX(0%) rotateZ(-0deg);
    }
    ${Tp} {
      transform: translateY(0%) translateX(0%) rotateZ(-0deg);
    }
    ${TT} {
      transform: translateX(-100%) rotateY(180deg);
    }
    ${Tar} {
      transform: translateX(0%) translateY(-0%) rotateZ(-0deg);
    }
    ${Tal} {
      transform: translateX(-100%) rotateY(180deg);
    }
  }
  .active {
    transform: translateY(-16px);
    transition: 0.2s linear;
    ${Cp} {
      transform: translateY(-19%) translateX(-138%) rotateZ(60deg)
        rotateY(180deg);
    }
    ${Ct} {
      transform: translateY(-19%) translateX(38%) rotateZ(-60deg);
    }
    ${Tp} {
      transform: translateX(4%) translateY(-13%) rotateZ(-17deg);
    }
    ${TT} {
      transform: translateX(-96%) translateY(-13%) rotateZ(17deg)
        rotateY(180deg);
    }
    ${Tar} {
      transform: translateX(9%) translateY(-21%) rotateZ(-17deg);
    }
    ${Tal} {
      transform: translateX(-109%) translateY(-21%) rotateZ(17deg)
        rotateY(180deg);
    }
  }
`;
