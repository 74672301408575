import styled from "styled-components";

export const GameContainer = styled.div<{ State: number }>`
  position: relative;
  height: 100%;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  aspect-ratio: 431/840;
  margin: 0px auto;
  z-index: 1;
  &::after{
    width: 100vw;
    height: 100vh;
    content:"";
    background-image: url("./static/mainBg.png");
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-position: center bottom;
    background-size: cover;
    z-index: -1;
  }
`;
export const GameWrapper = styled.div`
  width: 100%;
  height: calc(100% - 21.668vw - 25.58vw);
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  gap: 10px;
`;

export const GameProgess = styled.div<{progess?:any}>`
  aspect-ratio: 146/17;
  background: linear-gradient(
    to right,
    #fff 0%,
    #fff 50%,
    #2e3b74 50%,
    #01a0d1 100%
  );
  background-position: ${({progess})=> progess ? progess < 100 ? -progess : -100 : 0}% 0%;
  background-size: 200%;
  width: 33.9%;
  border-radius: 5%/47%;
  position: absolute;
  top: 0;
  transform: translateY(-40%);
  right: 4%;
  display: flex;
  align-items: end;
  justify-content: flex-end;
  span {
    color: #2f93c6;
    text-align: right;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translateY(-50%);
  }
`;

export const GamePoins = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  padding-top: 10.5%;
  align-items: center;
  h4 {
    color: #fff;
    text-align: center;
    text-shadow: 0px 2.34px 0px #000;
    -webkit-text-stroke-width: 0.780040979385376;
    -webkit-text-stroke-color: #000;
    font-family: Poppins;
    font-size: 5vw;
    font-style: normal;
    font-weight: 900;
    line-height: 100%; 
    letter-spacing: -0.749px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  @media only screen and (min-width: 430px) {
    padding-top: 20px;
    h4 {
      font-size: 25px;
    }
  }
`;
export const Energy = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  aspect-ratio: 99/28;
  max-width: 120px;
  align-items: flex-end;
  justify-content: flex-end;
  img {
    height: 100%;
    aspect-ratio: 1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
`;
export const MascotWapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */
  height: 70%;
  margin: 0 auto;
  aspect-ratio: 356/392;
  position: relative;
  z-index: 1;
`;
export const TextPoints = styled.p`
  position: absolute;
  animation: flowPoint 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  font-family: ErasBoldITC;
  font-size: 22px;
  color: #fff;
  z-index: 10;
  opacity: 0;
  text-align: center;
  text-shadow: 0px 1.554px 0px #ffffff25;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000000;
  font-family: Coda;
  font-size: 22.054px;
  font-style: normal;
  font-weight: 800;
  z-index: -1;
  line-height: 125%; 
  pointer-events: none;
  background: #ffffff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  &.is-click {
    pointer-events: auto;
  }
  @keyframes flowPoint {
    0% {
      transform: translateY(-50%) translateY(-50%);
    }
    10% {
      opacity: 1;
      transform: translateY(-100%) translateY(-50%);
    }
    80% {
      transform: translateY(-500%) translateY(-50%);
      opacity: 1;
    }
    100% {
      transform: translateY(-500%) translateY(-50%);
      opacity: 0;
    }
  }
`;
export const EnergyFloor = styled.div`
  width: 90%;
  height: 20px;
  padding-right: 6.67%;
  border-radius: 21px;
  background-color: #00000052;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  backdrop-filter: blur(6.5px);
  h5 {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 3.48vw;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 133.333% */
  }
  span {
    color: #959595;
    font-size: 2.7vw;
  }
  @media only screen and (min-width: 430px) {
    h5 {
      font-size: 15px;
    }
    span {
      color: #959595;
      font-size: 12px;
    }
  }
`;
