import { instance } from '../instance';

export const KIBBLE_API = {
  getInfoToken(contract: any) {
    return instance.get(`/token/get_token_info?address=${contract}`);
  },
  simulateCreatePool(params: any) {
    return instance.post(`/dex/pools/simulate_create_pool`, params);
  },
  confirmSimulateCreatePool(params: any) {
    return instance.post(`/dex/liquidity/provide`, params);
  },
  getAllPools(params: any) {
    return instance.post(
      `/dex/pools/balances?load_lp_accounts=${params.load_lp_accounts}`
    );
  },
  getAllAssets(params: any) {
    return instance.get(`/assets?is_conmunity=${params}`);
  },
  getPoolDetail(params: any, address: any) {
    if (address) {
      return instance.get(
        `/pools/detail?pool_address=${params}&wallet_address=${address}`
      );
    } else {
      return instance.get(`/pools/detail?pool_address=${params}`);
    }

    // return instance.get(`/pools/detail?pool_address=${params}`);
  },
  addNewAssetToken(params: any) {
    return instance.post(`/assets/create`, params);
  },
  checkTokenInPools(params: any) {
    return instance.get(
      `/pools/check?token0_address=${params.token0_address}&token1_address=${params.token1_address}`
    );
  },
  searchToken(params: any) {
    return instance.post(`/token/search`, params);
  },
  checkStatusSwap(params: any) {
    return instance.get(
      `/transactions/status?wallet_address=${params.wallet_address}&query_id=${params.query_id}`
    );
  },
  submitFavoriteToken(params: any) {
    return instance.post(`/assets/favorite`, params);
  },
  submitPoint(param: any) {
    return instance.post('/points/click', param);
  },
  loginWithTele(param: any) {
    return instance.post('/auth/connect-telegram', param);
  },
  sendNumOfClick(param: any) {
    return instance.post('/points/click', param);
  },
  getInfoUser() {
    return instance.get(`/users/info`);
  },
  getLeaderBoard(params: any) {
    return instance.get(
      `/users/leaderboard?pagination[page]=${
        params ? params.page : 1
      }&pagination[perPage]=100`
    );
  },
  getUserListTask() {
    return instance.get(
      `/users/tasks?pagination[page]=1&pagination[perPage]=50`
    );
  },
  getListTask() {
    return instance.get(
      `/tasks`
    );
  },
  getTaskClaim(params: any) {
    return instance.get(`/tasks/${params.id}/claim`);
  },
  getMysteryTask() {
    return instance.get(`/tasks/mysteries`);
  },
  submitTaskComplete(param: any) {
    return instance.post(`/tasks/${param.id}/do`, {
      is_content_creation: param.is_content_creation,
      link: param.link,
    });
  },
  getInfoCheckin() {
    return instance.get(`/tasks/daily-quest/me`);
  },
  dailyCheckin() {
    return instance.get(`/tasks/checkin-info`);
  },
  getListFriends(param?: any) {
    return instance.get(
      `/users/friends?pagination[page]=${
        param ? param.page : 1
      }&pagination[perPage]=12`
    );
  },
  getListPointOfInviteFriends() {
    return instance.get(`/points/point-friend`);
  },
  claimPointInviteFriend(param: any) {
    return instance.post('/users/claim-point', param);
  },
  getListPackageEnergy() {
    return instance.get(`/energy/next`);
  },
  buyEnergy() {
    return instance.get(`/energy/buy-energy`);
  },
  refillEnergy() {
    return instance.get(`/energy/refill-energy`);
  },
  getTimeNextRefill() {
    return instance.get(`/energy/refill-next`);
  },
};
