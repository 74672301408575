import styled from "styled-components";
import {
  BoxCoin,
  BoxUserFloor,
  BoxUserHeader,
} from "../../components/Header/styledv2";

export const BoxPosition = styled.div`
  width: 92.3%;
  aspect-ratio: 394/133;
  margin: 0px auto;
  margin-top: 1.16vw;
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    width: 100%;
    aspect-ratio: 394/109;
    border-radius: 11px;
    background: linear-gradient(180deg, #036aa7 0%, #01295a 100%);
    z-index: -1;
    bottom: 0;
    left: 0;
    position: absolute;
  }
  @media only screen and (min-width: 430px) {
    /* margin-top: 5px; */
  }
`;
export const BoxPositionTT = styled.div`
  width: 100%;
  aspect-ratio: 392/49;
  background-image: url("./static/BgPosition.png");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    color: #fff;
    text-align: center;
    text-shadow: 0px 3px 0px #000;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
    font-family: Poppins;
    font-size: 4.65vw;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
  }
  @media only screen and (min-width: 430px) {
    h1 {
      font-size: 20px;
    }
  }
`;

export const BoxPositionDes = styled.div`
  width: 100%;
  aspect-ratio: 394/84;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  box-sizing: border-box;
`;
export const BoxPositionDesVariable = styled.div`
  width: 94.67%;
  aspect-ratio: 374/70;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  ${BoxUserHeader} {
    background: none;
    ${BoxUserFloor} {
      width: 95%;
    }
  }
`;
export const BoxPositionIpoint = styled.div`
  width: 35%;
  aspect-ratio: 126/70;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  ${BoxCoin} {
    height: 50%;
    p {
      font-size: 2.6vw;
    }
  }
  @media only screen and (min-width: 430px) {
    ${BoxCoin} {
      p {
        font-size: 11px;
      }
    }
  }
`;
export const BoxLeaderboard = styled.div`
  width: 100%;
  height: calc(100% - 31.86vw);
  overflow-y: auto;
  @media only screen and (min-width: 430px) {
    height: calc(100% - 135px);
  }
`;

export const BoxLeaderboardFloor = styled.div`
  width: 92.3%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 4px auto 0px;
  height: fit-content;
  box-sizing: border-box;
`;
export const BoxLeaderboardElm = styled.div`
  width: 100%;
  border-radius: 9px;
  background: rgba(21, 47, 82, 0.58);
  backdrop-filter: blur(2px);
  aspect-ratio: 397/58;
  padding: 1.16vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  @media only screen and (min-width: 430px) {
    padding: 5px;
  }
`;

export const BoxAvatar = styled.div<{ rank?: any }>`
  display: flex;
  align-items: center;
  width: 22.16%;
  aspect-ratio: 88/47;
  display: flex;
  div {
    &:nth-child(1) {
      width: 46.59%;
      aspect-ratio: 41/45;
      border-radius: 13px 0px 0px 13px;
      background: ${({ rank }) =>
        rank === 1
          ? "linear-gradient(270deg, #d99400 0%, #e3c846 100%)"
          : rank === 2
          ? "linear-gradient(270deg, #7083AC 0%, #CCCCE5 100%)"
          : rank === 3
          ? "linear-gradient(270deg, #B1713C 0%, #E09F5D 100%)"
          : ""};
      box-shadow: 0px -3px 0px 0px ${({ rank }) =>
          rank === 1
            ? "rgba(154, 95, 0, 0.25)"
            : rank === 2
            ? "rgba(93, 111, 169, 0.48)"
            : rank === 3
            ? "rgba(172, 89, 16, 0.48)"
            : "#00000000"} inset;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:nth-child(2) {
      width: 53.41%;
      aspect-ratio: 1;
      border-radius: 3px;
      overflow: hidden;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
    p {
      color: #fff;
      text-align: center;
      text-shadow: 0px 3px 0px #000;
      -webkit-text-stroke-width: 1;
      -webkit-text-stroke-color: #000;
      font-family: Poppins;
      font-size: 4.65vw;
      font-style: normal;
      font-weight: 900;
      line-height: 100%;
    }
  }
  @media only screen and (min-width: 430px) {
    div {
      p {
        font-size: 20px;
      }
    }
  }
`;
export const BoxLevel = styled.div`
  width: 41%;
  aspect-ratio: 163/36;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  h6 {
    color: #fff;
    font-family: Poppins;
    font-size: 3.25vw;
    font-style: normal;
    font-weight: 800;
    line-height: 142.857%;
  }
  @media only screen and (min-width: 430px) {
    h6 {
      font-size: 14px;
    }
  }
`;
export const BoxLv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const BoxProgess = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17.17%;
  aspect-ratio: 28/14;
  p {
    color: #65afff;
    text-align: center;
    font-family: Poppins;
    font-size: 3.25vw;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
  }
  @media only screen and (min-width: 430px) {
    p {
      font-size: 14px;
    }
  }
`;
export const BoxProgessVal = styled.div<{ val?: any }>`
  display: flex;
  align-items: center;
  height: 4px;
  justify-content: space-between;
  width: 80%;
  span {
    display: flex;
    width: 22%;
    height: 4px;
    transform: skew(30deg);
    position: relative;
    background-color: #0a1f3e;
    overflow: hidden;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      transform: translateX(-100%);
      background-color: #65afff;
      z-index: 1;
    }
    ${({ val }) => {
      let a = Math.ceil(val / 25);
      let b = val % 25;
        let text = "";
       for (let i = 0; i < a; i++) {
        text = text + `
            &:nth-child(${i}){
              &::after {
              content: "";
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0px;
              left: 0px;
              transform: translateX(0%);
              background-color: #65afff;
              z-index: 1;
            }
          }`;
      }
      
      return text + `
            &:nth-child(${a}){
              &::after {
              content: "";
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0px;
              left: 0px;
              transform: translateX(${b ? -100 + b * 4 : 0 }%);
              background-color: #65afff;
              z-index: 1;
            }
          }`
    }}
  }
`;
export const BocVal = styled.div`
  width: 27.2%;
  aspect-ratio: 108/31;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
  }
  div {
    width: 90%;
    aspect-ratio: 97/24;
    border-radius: 16.13px;
    background: rgba(102, 115, 170, 0.52);
    backdrop-filter: blur(1.536210060119629px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    p {
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 2.5vw;
      font-style: normal;
      font-weight: 700;
      line-height: 15.362px;
      letter-spacing: -0.768px;
      width: 80%;
    }
  }
  @media only screen and (min-width: 430px) {
    div {
      p {
        font-size: 11px;
      }
    }
  }
`;
