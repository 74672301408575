import {
  BoxDaily,
  BoxDailyElm,
  BoxDailyVal,
  DailyList,
  GameContainer,
  BtnClaim,
  BoxVal,
  BoxListTask,
  ListTask,
  TaskLogo,
  BoxImgLogo,
  BoxImgLogoVal,
  TaskDetail,
  TaskDetailTT,
  ContentBox,
  BoxListTaskFloor,
} from "./styledv2";
import earnDailyImg from "../../assets/Game/Earn/earn_daily_01.png";
import { useContext, useEffect, useState } from "react";
import { ContextProviderWrapper } from "../../components/Context";
import TitleScreen from "../../components/Common/Title";
import { FloorFooter, FloorHeader } from "../../Layout/Dashboard/styled";
import { useDispatch, useSelector } from "react-redux";
import { instance } from "../../services/instance";
import toast from "react-hot-toast";
import {
  getCheckinInfo,
  getDataInfoUser,
  getDataListTask,
} from "../../redux/features/pointSlice";
import { useInitData } from "@tma.js/sdk-react";
import { convertFormatNumber } from "../../utils/convertFormatNumber";

const EarnV2 = () => {
  const [modalClaim, setModalClaim] = useState(false);
  const { setOverflow } = useContext(ContextProviderWrapper)!;
  const { listTask, dailyCheckin } = useSelector((state: any) => state.point);
  const [loading, setLoading] = useState(false);
  const dataUserTele: any = useInitData();
  const [isClaimDaily, setIsClaimDaily] = useState(false);
  const [currentTask, setCurrentTask]: any = useState();
  const {
    initData: { user },
  } = dataUserTele;
  const dispatch = useDispatch();
  const handleClaim = async (task: any) => {
    setLoading(true);
    setCurrentTask(task);
    let res: any;
    if (task?.user_task[0]?.status === "CLAIM") {
      res = await instance.get(`/tasks/${task?.task_id}/claim`);
    } else {
      res = await instance.get(`/tasks/${task?.task_id}/do`);
    }
    if (res.status !== 202) {
      toast.error("An error occurred, please try again.");
    } else {
      // @ts-ignore
      await dispatch(getDataListTask());
      // @ts-ignore
      await dispatch(getDataInfoUser(user));
    }
    if (task?.user_task[0]?.status === "CLAIM") {
      toast.success("Successfully!");
      setLoading(false);
    } else {
      setTimeout(() => {
        toast.success("Successfully!");
        setLoading(false);
      }, 3000);
    }
  };
  const handleClaimDaily = async () => {
    const Ress = await instance.get(`/tasks/checkin`);
    if (Ress.status === 200) {
      toast.success("Successfully!");
      // @ts-ignore
      await dispatch(getDataInfoUser(user));
      // @ts-ignore
      await dispatch(getCheckinInfo());
    } else {
      toast.error("An error occurred, please try again.");
    }
  };
  const showModalClaim = () => {
    setModalClaim(true);
    setOverflow(true);
  };
  useEffect(() => {
    setIsClaimDaily(
      !dailyCheckin?.last_checkin ||
        Date.now() - new Date(dailyCheckin?.last_checkin).getTime() >= 86400000
    );
  }, [dailyCheckin?.last_checkin]);

  const handleCheckinUi = (item: number) => {
    if (!dailyCheckin?.checkin_count && item === 1) {
      return "active";
    } else if (item <= dailyCheckin?.checkin_count) {
      return "pre";
    } else if (item - 1 === dailyCheckin?.checkin_count) {
      return "active";
    } else {
      return "next";
    }
  };

  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const nextMidnight = new Date();
      nextMidnight.setHours(24, 0, 0, 0);

      const timeDiff = nextMidnight.getTime() - now.getTime();
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      setTimeLeft({
        hours,
        minutes,
        seconds,
      });
    };

    calculateTimeLeft();

    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, []);
  return (
    <GameContainer>
      <FloorHeader />
      <BoxDaily>
        <h3>DAILY CLAIM</h3>
        <DailyList>
          {[1, 2, 3, 4, 5, 6, 7].map((item: any, index: number) => {
            return (
              <BoxDailyElm mode={handleCheckinUi(item)}>
                <p>{index + 1}</p>
              </BoxDailyElm>
            );
          })}
        </DailyList>
        <BoxDailyVal>
          <BoxVal>
            <img src="/static/ItonLogo.png" alt="" />
            <p>
              {convertFormatNumber(
                dailyCheckin?.checkin_count
                  ? dailyCheckin?.checkin_count * 10000
                  : 10000
              )}
            </p>
          </BoxVal>
          <BtnClaim
            isclaim={isClaimDaily}
            onClick={() => {
              isClaimDaily && handleClaimDaily();
            }}
          >
            {isClaimDaily ? "CLAIM" : `${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`}
          </BtnClaim>
        </BoxDailyVal>
      </BoxDaily>
      <BoxListTask>
        <BoxListTaskFloor>
          {listTask?.data?.data?.map((item: any, index: any) => {
            let isClaim = item?.user_task[0]?.status === "CLAIM";
            return (
              item?.user_task[0]?.status !== "COMPLETED" && (
                <ListTask isClaim={isClaim} key={item?.task_id}>
                  <TaskLogo isClaim={isClaim}>
                    <BoxImgLogo bg={item?.thumbnail_info?.file_path} >
                      <BoxImgLogoVal isClaim={isClaim}>
                        <img src={ "/static/ItonLogo.png"} alt="" />
                        <p>{item?.point}</p>
                      </BoxImgLogoVal>
                    </BoxImgLogo>
                  </TaskLogo>
                  <TaskDetail>
                    <TaskDetailTT isClaim={isClaim}>
                      <h1>{item?.name}</h1>
                    </TaskDetailTT>
                    <ContentBox isClaim={isClaim}>
                      <p>{item?.description}</p>
                      {isClaim ? (
                        <button
                          onClick={() => {
                            handleClaim(item);
                          }}
                        >
                          {loading && currentTask.task_id === item?.task_id ? (
                            <div className="loader"></div>
                          ) : isClaim ? (
                            "Claim"
                          ) : (
                            "Go to"
                          )}
                        </button>
                      ) : (
                        <a
                          href={item?.url}
                          target="_blank"
                          onClick={() => {
                            handleClaim(item);
                          }}
                        >
                          {loading && currentTask.task_id === item?.task_id ? (
                            <div className="loader"></div>
                          ) : isClaim ? (
                            "Claim"
                          ) : (
                            "Go to"
                          )}
                        </a>
                      )}
                    </ContentBox>
                  </TaskDetail>
                </ListTask>
              )
            );
          })}
        </BoxListTaskFloor>
      </BoxListTask>
      <FloorFooter />
    </GameContainer>
  );
};

export default EarnV2;
