import {
  BoxElmWallet,
  BoxPositionDesShop,
  BoxPositionWl,
  BoxWalletInfor,
  BtnBuy,
  BtnConnect,
  EnergyBoxElem,
  ShopBoxFlex,
  ShopTitle,
  TokenList,
} from "./styled";
import icon01 from "../../assets/Game/Assets/icn_01.svg";
import buttonBack from "../../assets/Game/Assets/button_back.png";
import product01 from "../../assets/Game/Assets/product_img_01.png";
import product02 from "../../assets/Game/Assets/product_img_02.png";
import product03 from "../../assets/Game/Assets/product_img_03.png";
import icnUser from "../../assets/Game/Assets/icn_user.png";
import { useEffect, useState } from "react";
import {
  useTonAddress,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import WalletModalInfo from "../../components/Common/WalletInfo";
import ItemProduct from "../Assets/ItemProduct";
import AssetsShopV2 from "../Assets/AssetsShopV2";
import bgTop from "../../assets/Game/Assets/Shop/bg_decor.png";
import { useBalance } from "../../hooks/useBalance";
import { convertFixed, convertFormatNumber } from "../../utils/convertFormatNumber";
import WalletInfo from "./WalletInfo";
import { useGetBalancesQuery } from "../../store/api/dexApiSlice";
import { GameContainer } from "../Earn/styledv2";
import { FloorFooter, FloorHeader } from "../../Layout/Dashboard/styled";
import {
  BoxLeaderboardFloor,
  BoxPosition,
  BoxPositionDes,
  BoxPositionTT,
} from "../Leaderboard/styled";
import { useDispatch, useSelector } from "react-redux";
import { getDataInfoUser, getDataListAsset, getListPackageEnergy } from "../../redux/features/pointSlice";
import styled from "styled-components";
import { instance } from "../../services/instance";
import toast from "react-hot-toast";
import { useInitData } from "@tma.js/sdk-react";

const AssetsNew = () => {
  const [showShop, setShowShop] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [tonConnectUI] = useTonConnectUI();
  const { tonBalance } = useBalance();
  const address = useTonAddress();
  const wallet = useTonWallet();
  const dispatch = useDispatch();
  const dataUserTele: any = useInitData();
  const {
    initData: { user },
  } = dataUserTele;

  const { listAsset , listPackageEnergy , infoUser } = useSelector(
    (state: any) => state.point
  );
  // wallet info
  const { data: balances }: any = useGetBalancesQuery(address, {
    pollingInterval: 1000 * 20,
    skip: !wallet,
  });
  const handleCallApi = async ()=>{
    // @ts-ignore
    await dispatch(getDataListAsset(address));
  }
  
  useEffect(() => {
    handleCallApi()
  }, [balances, address]);
  const handleBuy = async (poitnBuy:number)=>{
    if(poitnBuy > infoUser?.points){
      toast.error("Invalid points")
      return
    }
    const result = await instance.get(`/energy/buy`);
    if(result.status === 200){
      toast.success("successfully")
      // @ts-ignore
      dispatch(getListPackageEnergy());
      // @ts-ignore
      dispatch(getDataInfoUser(user));
    }else{
      toast.error("Error")
    }
  }
  return (
    <GameContainer>
      <FloorHeader />
      {!showShop ? (
        <>
          <BoxPosition>
            <BoxPositionTT>
              <h1>My Wallet</h1>
            </BoxPositionTT>
            <BoxPositionDes>
              <BoxWalletInfor>
                <div>
                  <p>
                    {address
                      ? `${address.slice(0, 4)}...${address.slice(-4)}`
                      : "--"}
                  </p>
                  <img src="./static/iconCopy.svg" />
                </div>
                <h6>{convertFixed(Number(tonBalance))} TON</h6>
              </BoxWalletInfor>
              <BtnConnect
                onClick={() => {
                  address ? setShowShop(true) : tonConnectUI.openModal()
                }}
              >
                {address ? `Energy Shop` : "Connect Wallet"}
              </BtnConnect>
            </BoxPositionDes>
          </BoxPosition>
          <TokenList>
            <BoxLeaderboardFloor>
              <WalletInfo />
            </BoxLeaderboardFloor>
          </TokenList>
        </>
      ) : (
        <>
          <BoxPositionWl>
            <BoxPositionTT>
              <h1>Energy Shop</h1>
            </BoxPositionTT>
            <BoxPositionDesShop>
              <BoxWalletInfor>
                <div>
                  <p>
                    {address
                      ? `${address.slice(0, 4)}...${address.slice(-4)}`
                      : "--"}
                  </p>
                  <img src="./static/iconCopy.svg" />
                </div>
                <h6>{convertFixed(Number(tonBalance))} TON</h6>
              </BoxWalletInfor>
            </BoxPositionDesShop>
          </BoxPositionWl>
          <TokenList>
            <BoxLeaderboardFloor>
              <ShopTitle>
                <span></span>
                <h4>Energy Shop</h4>
                <span></span>
              </ShopTitle>
              <ShopBoxFlex>
                {listPackageEnergy?.map((item: any , index:number) => {
                  return (
                    <EnergyBoxElem key={item.energy_limit}  >
                      <p>{item?.energy_limit && convertFormatNumber(item?.energy_limit)}E</p>
                      <img src="./static/EnergyIconLg.png" />
                      <BtnBuy islock={index !== 0} onClick={()=>{index === 0 && handleBuy(item?.point)}}>
                        <img src="./static/ItonLogo.png" />
                        <p>{item?.point && convertFormatNumber(item?.point)}</p>
                      </BtnBuy>
                    </EnergyBoxElem>
                  );
                })}
              </ShopBoxFlex>
              <ShopTitle>
                <span></span>
                <h4>iTON Token</h4>
                <span></span>
              </ShopTitle>
              <ShopBoxFlex>
                {[1, 2, 3].map((item: any) => {
                  return (
                    <EnergyBoxElem>
                      <p>??? iTon</p>
                      <img src="./static/TonLogo.png" />
                      <BtnBuy>
                        <img src="./static/ItonLogo.png" />
                        <p>TBA</p>
                      </BtnBuy>
                    </EnergyBoxElem>
                  );
                })}
              </ShopBoxFlex>
            </BoxLeaderboardFloor>
          </TokenList>
        </>
      )}
      <FloorFooter />
    </GameContainer>
  );
};
export default AssetsNew;
