import styled from "styled-components";

export const DashboardContainer = styled.div`
  overflow: hidden;
  height: 100%;
  margin: 0px auto;
  position: relative;
  max-width: 100%;
`;

export const DashboardMain = styled.div`
  margin: 0 auto;
  height: 100%;
  width: 100%;
`;
export const FloorHeader = styled.div`
  width: 100%;
  min-height: 21.668vw;
`;
export const FloorFooter = styled.div`
  width: 100%;
  min-height: 25.58vw;
`;
