import { useEffect, useState } from "react";
import {
  BoxMaxCosLoading,
  BoxText,
  LoadingContainer,
  LoadingProgessContainer,
  LoadingProgessVal,
  LoadingProgessWapper,
} from "./styled";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "../../redux/store";
import {
  getCheckinInfo,
  getDataInfoUser,
  getDataLeaderBoard,
  getDataListAsset,
  getDataListFriends,
  getDataListTask,
  getListPackageEnergy,
  getListPointOfInviteFriends,
  getTimeNextRefill,
} from "../../redux/features/pointSlice";
import { useInitData } from "@tma.js/sdk-react";
import { KIBBLE_API } from "../../services/api";
import WebApp from "@twa-dev/sdk";
import { useTonAddress } from "@tonconnect/ui-react";
import { getTokenInWallet } from "../../utils/getTokenInWallet";
const preloadComponent = () => {
  import("../Clicker/index");
  import("../AssetsNew");
  import("../Earn/indexv2");
  import("../Friends");
  import("../Leaderboard");
};

export default function LoadingV2({ isLogin, setIsLogin }: any) {
  const [processing, setProcessing] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { initDataUnsafe } = WebApp;

  const dataUserTele: any = useInitData();
  const preloadImage = (url: string) => {
    return new Promise<void>((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve();
      img.onerror = () => reject();
    });
  };

  const preloadImages = async (urls: string[]) => {
    const promises = urls.map((url) => preloadImage(`./static/${url}`));
    try {
      await Promise.all(promises);
    } catch (error) {
      console.error("Lỗi khi tải hình ảnh:", error);
    }
  };
  const {
    initData: { user },
  } = dataUserTele;

  const intervalLoadingProcess = "";

  const address = useTonAddress();

  const handlePrepareData = async () => {
    try {
      await Promise.all([
        //
        // 
        // dispatch(getTimeNextRefill()),
        // dispatch(getDataListAsset(address)),
        dispatch(getListPackageEnergy()),
        dispatch(getListPointOfInviteFriends()),
        dispatch(getDataListFriends()),
        dispatch(getCheckinInfo()),
        dispatch(getDataListTask()),
        dispatch(getDataLeaderBoard()),
        dispatch(getDataInfoUser(user)),
        preloadComponent(),
        preloadImages(urls),
      ]);
      setInterval(() => {
        const percentRandom = Math.floor(Math.random() * 25);
        setProcessing((prev) => Math.min(prev + percentRandom, 100));
      }, 200);
    } catch (error) {
      console.log("handlePrepareData err", error);
    }
  };

  const handleLoginWithTele = async (payload: any) => {
    try {
      const { data } = await KIBBLE_API.loginWithTele(payload);
      await localStorage.setItem("token", data.token);
      setIsLogin(true);
      setProcessing(25);
    } catch (error) {
      console.log("handleLoginWithTele err", error);
    }
  };

  useEffect(() => {
    if (user) {

      const startParams = initDataUnsafe.start_param;
      const listAddressFromParams: any = startParams?.split("=");
      const payload = {
        telegram_id: `${user.id}`,
        telegram_name: `${user.firstName} ${user.lastName}`,
        telegram_username: user.username,
        referral_code:
          listAddressFromParams && listAddressFromParams.length > 0
            ? listAddressFromParams[0]
            : "",
      };
      handleLoginWithTele(payload);
    }
  }, []);

  useEffect(() => {
    if (!isLogin) return;
    handlePrepareData();
  }, [isLogin]);

  useEffect(() => {
    if (processing === 100) {
      clearInterval(intervalLoadingProcess);
      navigate("/game");
    }
  }, [processing]);

  return (
    <LoadingContainer>
      <BoxText />
      <BoxMaxCosLoading />
      <LoadingProgessContainer>
        <LoadingProgessWapper>
          <p>{processing}%</p>
          <LoadingProgessVal val={processing}></LoadingProgessVal>
        </LoadingProgessWapper>
      </LoadingProgessContainer>
    </LoadingContainer>
  );
}

const urls = [
  "WalletLogoBg.png",
  "activeArrow.png",
  "avtDefaut.png",
  "BgHeader.png",
  "BgPosition.png",
  "BgPosition.png",
  "BgPoWl.png",
  "BgValTaskL.png",
  "BgValTaskL2.png",
  "BgWallet.png",
  "BoxAvt.png",
  "cr.png",
  "DefautAvt.png",
  "EnergyBoxBg.png",
  "EnergyIconLg.png",
  "iconCopy.svg",
  "iconSvg.svg",
  "imgMascot.svg",
  "ItonLogo.png",
  "layoutBg.png",
  "mainBg.png",
  "NavBg.png",
  "StarBlue.png",
  "Ta.png",
  "TaskLBg.png",
  "TaskLBg2.png",
  "TaskLgBg.png",
  "TaskLgBg2.png",
  "TaskListBg.png",
  "TaskListBg2.png",
  "TonLogo.png",
  "tr.png",
];
