import { styled } from "styled-components";

export const LoadingContainer = styled.div`
  background-image: url("./static/iTonLoadingBg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BoxText = styled.div`
  width: 63.72%;
  max-width: 274px;
  aspect-ratio: 274/164;
  background-image: url("./static/LoadingText.png");
  background-size: cover;
  background-position: center;
`;
export const BoxMaxCosLoading = styled.div`
  width: 59.5%;
  max-width: 256px;
  aspect-ratio: 256/320;
  background-image: url("./static/MascotLoading.png");
  background-size: cover;
  background-position: center;
`;
export const LoadingProgessContainer = styled.div`
  border-radius: 8px;
  border: 2px solid #1b7bd6;
  background: #fff;
  box-shadow: 0px 4px 0px 0px #42a3ff;
  width: 200px;
  height: 17px;
  position: fixed;
  bottom: 36px;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
`;

export const LoadingProgessWapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
  box-sizing: border-box;
  p {
    color: #9fb1ff;
    text-align: right;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 11px */
    letter-spacing: -0.33px;
  }
`;
export const LoadingProgessVal = styled.div<{ val?: any }>`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  width: ${({ val }) => (val ? val : 0)}%;
  content: "";
  background-color: #5071ff;
  overflow: hidden;
  z-index: 5;
  box-sizing: border-box;
  &::after {
    width: 195px;
    height: 17px;
    content: "${({ val }) => (val ? val : 0)}%  ";
    color: #ffffff;
    text-align: right;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; 
    letter-spacing: -0.33px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5px;
    box-sizing: border-box;
    left:0px;
    top:50%;
    transform: translateY(-50%);
    position: absolute;
  }
`;
