import {
  BoxAvatarFriend,
  BoxPositionBtnInvite,
  BoxPositionFriend,
  BtnCopy,
  ClaimBox,
  ClaimBtn,
  FriendList,
} from "./styled";
import { GameContainer } from "../Earn/styledv2";
import { FloorFooter, FloorHeader } from "../../Layout/Dashboard/styled";
import {
  BocVal,
  BoxAvatar,
  BoxLeaderboardElm,
  BoxLeaderboardFloor,
  BoxLevel,
  BoxLv,
  BoxPosition,
  BoxPositionTT,
  BoxProgess,
  BoxProgessVal,
} from "../Leaderboard/styled";
import { useDispatch, useSelector } from "react-redux";
import { getListPointOfInviteFriends, getToastMess } from "../../redux/features/pointSlice";
import { useInitData } from "@tma.js/sdk-react";
import toast from "react-hot-toast";
import WebApp from "@twa-dev/sdk";
import { convertFormatNumber } from "../../utils/convertFormatNumber";
import { RULE_GAME } from "../../constant";
import { instance } from "../../services/instance";

const Friends = () => {
  const dataUserTele: any = useInitData();

  const { listFriends, listPointOfInviteFriends, infoUser } = useSelector(
    (state: any) => state.point
  );
  const dispatch = useDispatch();

  const {
    initData: { user },
  } = dataUserTele;
  console.log("infoUser", infoUser);

  const handleInviteFriend = (type: string) => {
    try {
      const referalCode =
        infoUser && infoUser.referral_code ? infoUser.referral_code : user.id;
      const link = `https://t.me/iTONGameBot/Click?startapp=${referalCode}`;
      const shareLink = `https://t.me/share/url?url=${encodeURIComponent(
        link
      )}`;
      if (type === "copy") {
        navigator.clipboard.writeText(link);
        toast.success("Copied");
      } else {
        WebApp.openTelegramLink(shareLink);
      }
    } catch (error) {
      console.log("handleInviteFriend err", error);
    }
  };
  const handleLoadLvprogess = (exp: any, level: any) => {
    switch (level) {
      case 0:
        return (exp / RULE_GAME.EXP.lv1) * 100;
        break;
      case 1:
        return (exp / RULE_GAME.EXP.lv2) * 100;
        break;
      case 2:
        return (exp / RULE_GAME.EXP.lv3) * 100;
        break;
      case 3:
        return (exp / RULE_GAME.EXP.lv4) * 100;
        break;
      case 4:
        return (exp / RULE_GAME.EXP.lv5) * 100;
        break;
      case 5:
        return (exp / RULE_GAME.EXP.lv6) * 100;
        break;
      case 6:
        return (exp / RULE_GAME.EXP.lv7) * 100;
        break;
      case 7:
        return 100;
        break;
    }
  };
  const handleClaim = async ()=>{
    const Res = await instance.get(`/users/claim`);
    if(Res.status === 200){
      toast.success("Success !")
      // @ts-ignore
      dispatch(getListPointOfInviteFriends())
    }else{
      toast.error("Error !")
    }
  }
  return (
    <GameContainer>
      <FloorHeader />
      <BoxPosition>
        <BoxPositionTT>
          <h1>Play with your friend</h1>
        </BoxPositionTT>
        <BoxPositionFriend>
          <BoxPositionBtnInvite
            onClick={(e) => {
              handleInviteFriend("");
            }}
          >
            <p>Invite Friends</p>
          </BoxPositionBtnInvite>
          <BtnCopy
            onClick={(e) => {
              handleInviteFriend("copy");
            }}
          >
            <img src="./static/iconSvg.svg" />
          </BtnCopy>
        </BoxPositionFriend>
      </BoxPosition>
      <FriendList>
        <BoxLeaderboardFloor>
          {listFriends?.data?.map((elm: any, index: number) => {
            return (
              <BoxLeaderboardElm>
                <BoxAvatarFriend rank={index + 1}>
                  <p>{index + 1}</p>
                  <img src={elm.avatar_url || "./static/avtDefaut.png"} />
                </BoxAvatarFriend>
                <BoxLevel>
                  <h6>{elm.fullname}</h6>
                  <BoxLv>
                    <BoxProgess>
                      <p>{elm.level}</p>
                    </BoxProgess>
                    <BoxProgessVal
                      val={handleLoadLvprogess(elm?.total_exp, elm?.point)}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </BoxProgessVal>
                  </BoxLv>
                </BoxLevel>
                <BocVal>
                  <img src="/static/ItonLogo.png" />
                  <div>
                    <p>{elm?.point && convertFormatNumber(elm?.point)}</p>
                  </div>
                </BocVal>
              </BoxLeaderboardElm>
            );
          })}
        </BoxLeaderboardFloor>
      </FriendList>
      <ClaimBox>
        <p>
          Claim 1,000 for Premium friend invited and 500 for Normal friend
          account
        </p>
        <ClaimBtn isclaim = {listPointOfInviteFriends} onClick={()=>{listPointOfInviteFriends && handleClaim()}} >
          {listPointOfInviteFriends
            ? `Claim ${
                listPointOfInviteFriends &&
                convertFormatNumber(listPointOfInviteFriends)
              }`
            : "Claimed"}
        </ClaimBtn>
      </ClaimBox>
      <FloorFooter />
    </GameContainer>
  );
};

export default Friends;
