import { GameFooterWrapper, NavFloor, SvgBox } from "./styledv2";
import nav_1 from "../../assets/Game/nav_1.png";
import nav_2 from "../../assets/Game/nav_2.png";
import nav_3 from "../../assets/Game/nav_3.png";
import nav_4 from "../../assets/Game/nav_4.png";
import nav_5 from "../../assets/Game/nav_5.png";
import { NavLink } from "react-router-dom";

const GameFooter = () => {
  return (
    <GameFooterWrapper>
      <NavFloor>
        {navData.map((item, index) => {
          return (
            <li key={index}>
              <NavLink to={item.to}>
                <SvgBox>
                  <SvgIcon />
                  <img src={item.icon} />
                </SvgBox>
                <p>{item.text}</p>
              </NavLink>
            </li>
          );
        })}
      </NavFloor>
    </GameFooterWrapper>
  );
};

const navData = [
  {
    id: 1,
    icon: nav_1,
    text: "EARN",
    to: "/earn",
  },
  {
    id: 2,
    icon: nav_2,
    text: "TOP 10",
    to: "/leaderboard",
  },
  {
    id: 3,
    icon: nav_3,
    text: "GAME",
    to: "/game",
  },
  {
    id: 4,
    icon: nav_4,
    text: "WALLET",
    to: "/assets",
  },
  {
    id: 5,
    icon: nav_5,
    text: "FRIENDS",
    to: "/friends",
  },
];

export default GameFooter;

const SvgIcon = () => {
  return (
    <>
      <svg
        width="68"
        height="78"
        viewBox="0 0 68 78"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_193_236)">
          <path
            d="M28.4758 2.09681C31.8792 0.131849 36.0724 0.131853 39.4758 2.09681L60.4279 14.1936C63.8314 16.1585 65.9279 19.7899 65.9279 23.7198V47.9133C65.9279 51.8433 63.8314 55.4747 60.4279 57.4396L39.4758 69.5364C36.0724 71.5013 31.8792 71.5013 28.4758 69.5364L7.52358 57.4396C4.12017 55.4747 2.02359 51.8433 2.02359 47.9133V23.7198C2.02359 19.7899 4.12017 16.1585 7.52359 14.1936L28.4758 2.09681Z"
            fill="#44ECF5" className="fill_svg"
          />
          <path
            d="M38.6905 3.45696L59.6427 15.5537C62.5601 17.2381 64.3574 20.351 64.3574 23.7198V47.9133C64.3574 51.2822 62.5601 54.3951 59.6427 56.0795L38.6905 68.1762C35.773 69.8606 32.1785 69.8606 29.2611 68.1762L8.30886 56.0795C5.39138 54.3951 3.59415 51.2822 3.59415 47.9133V23.7198C3.59415 20.351 5.39139 17.2381 8.30887 15.5537L29.2611 3.45696C32.1785 1.77255 35.773 1.77255 38.6905 3.45696Z"
            stroke="#1B7BD6"
            stroke-width="3.14112"  className="stroke_svg"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_193_236"
            x="0.616923"
            y="0.623077"
            width="66.7178"
            height="76.7938"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="1.40676"
              operator="dilate"
              in="SourceAlpha"
              result="effect1_dropShadow_193_236"
            />
            <feOffset dy="5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.258333 0 0 0 0 0.639082 0 0 0 0 1 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_193_236"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_193_236"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
};
