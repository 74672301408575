import { Outlet } from "react-router-dom";
import { DashboardContainer, DashboardMain, FloorFooter, FloorHeader } from "./styled";
import GameHeader from "../../components/Header";
import GameFooter from "../../components/Footer";
import GameHeaderV2 from "../../components/Header/indexv2";

const LayoutDashboard = () => {
  return (
    <DashboardContainer>
      <GameHeaderV2 />
      <DashboardMain>
        <Outlet />
      </DashboardMain>
      <GameFooter />
    </DashboardContainer>
  );
};
export default LayoutDashboard;
