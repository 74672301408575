import styled from "styled-components";

export const GameHeaderWrapper = styled.div`
  position: absolute;
  background-image: url("./static/BgHeader.png");
  width: 100%;
  aspect-ratio: 430/93;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
`;

export const GameHeaderMain = styled.div`
  height: 63%;
  aspect-ratio: 400/58;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const BoxUserHeader = styled.div`
  background-image: url("./static/BoxAvt.png");
  width: 58%;
  aspect-ratio: 233/54;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const BoxUserFloor = styled.div`
  width: 84.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  aspect-ratio: 197/36;
  img {
    border: 3px solid #65afff;
    height: 100%;
    max-height: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
  }
`;
export const BoxUserName = styled.div`
  height: 100%;
  aspect-ratio: 145/36;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    color: #fff;
    font-family: Poppins;
    font-size: 3.7vw;
    font-style: normal;
    font-weight: 800;
    line-height: 125%;
  }
  @media only screen and (min-width: 430px) {
    p {
      font-size: 16px;
    }
  }
`;
export const Progess = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h6 {
    color: #65afff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
    font-size: 3.2vw;
  }
  .progess {
    width: 87%;
    display: flex;
    justify-content: space-between;
    aspect-ratio: 135/4;
    div {
      width: 23%;
      height: 100%;
      background-color: #0a1f3e;
      transform: skew(-30deg);
      position: relative;
    }
  }
  @media only screen and (min-width: 430px) {
    h6 {
      font-size: 14px;
    }
  }
`;
export const BoxCoin = styled.div`
  height: 65%;
  aspect-ratio: 159/41;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    height: 100%;
    aspect-ratio: 1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  div {
    height: 54%;
    aspect-ratio: 150 / 31;
    border-radius: 21px;
    background: rgba(102, 115, 170, 0.52);
    backdrop-filter: blur(2px);
    padding: 1.16vw 4.41vw;
    display: flex;
    align-items: center;
    p {
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 3.2vw;
      font-style: normal;
      font-weight: 700;
      line-height: 142.857%; /* 142.857% */
      letter-spacing: -0.23vw;
      width: 100%;
    }
  }
  @media only screen and (min-width: 430px) {
    div {
      padding: 5px 19px;
      p {
        font-size: 14px;
        width: 100%;
        text-align: right;
        letter-spacing: -1px;
      }
    }
  }
`;
