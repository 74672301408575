import styled from "styled-components";

export const BoxWalletInfor = styled.div`
  div {
    border-radius: 16.13px;
    background: rgba(102, 115, 170, 0.52);
    backdrop-filter: blur(1.536210060119629px);
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 7px;
    margin-bottom: 5px;
    p {
      color: #fff;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px; /* 153.846% */
    }
  }
  h6 {
    color: #fff;
    text-shadow: 0px 3px 0px #000;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
    font-family: Poppins;
    font-size: 5.58vw;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
  }
  @media only screen and (min-width: 430px) {
    h6 {
      font-size: 24px;
    }
  }
`;
export const BtnConnect = styled.button`
  display: flex;
  width: 139px;
  padding: 15.672px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 11.704px;
  color: #fff;
  text-align: center;
  text-shadow: 0px 2.224px 0px #000;
  -webkit-text-stroke-width: 0.7412850260734558px;
  -webkit-text-stroke-color: #000;
  font-family: PoppinsBold;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%; /* 15px */
  border-radius: 7px;
  background: #fff;
  border: none;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  p {
    position: relative;
    z-index: 1;
  }
  &::after {
    position: absolute;
    width: 139px;
    height: 9.758px;
    background: #707070;
    content: "";
    bottom: 0px;
    left: 0px;
  }
`;

export const TokenList = styled.div`
  width: 100%;
  height: calc(100% - 31.86vw);
  overflow-y: auto;
  padding-top: 15px;
  @media only screen and (min-width: 430px) {
    height: calc(100% - 135px);
  }
`;
export const BoxElmWallet = styled.div`
  width: 100%;
  aspect-ratio: 395/54;
  background-image: url("./static/BgWallet.png");
  background-size: cover;
  background-position: left top;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  overflow: hidden;
  h5 {
    color: #fff;
    text-shadow: 0px 3px 0px #000;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
    font-family: PoppinsBold;
    font-size: 3.72vw;
    font-style: normal;
    font-weight: 900;
    line-height: 100%; /* 16px */
  }
  @media only screen and (min-width: 430px) {
    h5 {
      font-size: 16px;
    }
  }
`;
export const BoxToken = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 10px;
  h5{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }
`;
export const LogoToken = styled.div`
  height: 100%;
  aspect-ratio: 66/54;
  background-image: url("./static/WalletLogoBg.png");
  background-position: top right;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 45%;
    aspect-ratio: 1;
    box-sizing: border-box;
  }
`;
export const BoxPositionWl = styled.div`
  width: 92.3%;
  aspect-ratio: 394/133;
  margin: 0px auto;
  margin-top: 1.16vw;
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    width: 100%;
    aspect-ratio: 394/109;
    border-radius: 11px;
    background: linear-gradient(180deg, #036aa7 0%, #01295a 100%);
    z-index: -2;
    bottom: 0;
    left: 0;
    position: absolute;
  }
  &::before {
    content: "";
    width: 100%;
    aspect-ratio: 394/109;
    border-radius: 11px;
    background-image: url("./static/BgPoWl.png");
    background-size: cover;
    background-position: center;
    z-index: -1;
    bottom: 0;
    left: 0;
    position: absolute;
  }
  @media only screen and (min-width: 430px) {
    margin-top: 5px;
  }
`;
export const BoxPositionDesShop = styled.div`
  width: 100%;
  aspect-ratio: 394/84;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  box-sizing: border-box;
`;
export const ShopTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0px;
  gap: 8px;
  span {
    flex: 1;
    background: #255b8e;
    box-shadow: 0px 1px 0px 0px #000000 inset;
    border-radius: 5px;
    height: 8px;
    display: flex;
  }
  h4 {
    color: #fff;
    text-align: center;
    text-shadow: 0px 2.224px 0px #000;
    -webkit-text-stroke-width: 0.7412850260734558;
    -webkit-text-stroke-color: #000;
    font-family: PoppinsBold;
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
  }
`;
export const ShopBoxFlex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
`;
export const EnergyBoxElem = styled.div`
  width: calc((100% - 16px) / 3);
  background-image: url("./static/EnergyBoxBg.png");
  background-size: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  aspect-ratio: 126/161;
  padding: 6px;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  img{
    max-width: 66%;
  }
  p {
    color: #fff;
    text-align: center;
    text-shadow: 0px 3px 0px #000;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
    font-family: Poppins;
    font-size: 3vw;
    font-style: normal;
    font-weight: 900;
    line-height: 100%; /* 15px */
  }
  @media only screen and (min-width: 430px) {
    p{
      font-size: 12px;
    }
  }
`;
export const BtnBuy = styled.div<{islock?:any}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  background: ${({islock})=> islock ? "#a3a3a3" : "#109ee6"} ;
  padding: 6px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  cursor: ${({islock})=> !islock ? "pointer" : "no-drop"} ;
  img{
    max-width: 20px;
  }
  &::after{
    content: "";
    width: 100%;
    height: 5px;
    background: ${({islock})=> islock ? "#575757" : "#153E89"} ; 
    position: absolute;
    z-index: 1;
    bottom: 0px;
    left: 0px;
  }
`;
export const ItonBoxElem = styled.div`
  width: calc((100% - 16px) / 3);
  background-image: url("./static/EnergyBoxBg.png");
  background-size: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  aspect-ratio: 126/161;
  padding: 6px;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  img{
    max-width: 66%;
  }
  p {
    color: #fff;
    text-align: center;
    text-shadow: 0px 3px 0px #000;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%; /* 15px */
  }
`;