import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { KIBBLE_API } from '../../services/api';
import { getValueSetting } from '../../utils/getValueSetting';
import { getTokenInWallet } from '../../utils/getTokenInWallet';

export type ToastMessType = {
  message: string;
  status: boolean;
};

interface DriverState {
  getPoints: any;
  maxPoint: any;
  leaderBoard: any;
  infoUser: any;
  listTask: any[];
  listAsset: any[];
  listFriends: any;
  energy: number;
  listPointOfInviteFriends: number[];
  toastMessage: ToastMessType | null;
  userLevel: any;
  dailyMessage: any;
  myterytask: null;
  listPackageEnergy: any;
  timeNextRefill: any;
  exps:any;
  dailyCheckin:any;
}

const initialState: DriverState = {
  getPoints: 0,
  maxPoint: 0,
  leaderBoard: null,
  infoUser: null,
  listTask: [],
  listAsset: [],
  energy: 0,
  listFriends: null,
  listPointOfInviteFriends: [],
  toastMessage: null,
  userLevel: null,
  dailyMessage: null,
  myterytask: null,
  listPackageEnergy: [],
  timeNextRefill: null,
  exps: null,
  dailyCheckin:null,
};

const pointSlice = createSlice({
  name: 'pointSlice',
  initialState,
  reducers: {
    getpoints(state, action) {
      state.getPoints = action.payload;
    },
    setExps(state, action) {
      state.exps = action.payload;
    },
    getMaxpointSuccess(state, action) {
      state.maxPoint = action.payload;
    },
    getEnergy(state, action) {
      state.energy = action.payload;
    },
    getLeaderBoadrdSuccess(state, action) {
      const { data, total, isLoadMore } = action.payload;
      const topUsers = isLoadMore
        ? state.leaderBoard?.data?.concat(data)?.splice(0, 200)
        : data;
      const dataConvert = {
        data: topUsers,
        total,
      };
      state.leaderBoard = dataConvert;
    },
    getInfoUserSuccess(state, action) {
      state.infoUser = action.payload;
    },
    getUserLevelSuccess(state, action) {
      state.userLevel = action.payload;
    },
    getListTaskSuccess(state, action) {
      state.listTask = action.payload;
    },
    getListAssetSuccess(state, action) {
      state.listAsset = action.payload;
    },
    getListFriendsSuccess(state, action) {
      const { data, total, isLoadMore } = action.payload;
      const dataConvert = {
        data: isLoadMore ? state.listFriends?.data?.concat(data) : data,
        total,
      };
      state.listFriends = dataConvert;
    },
    getListPointOfInviteFriendsSuccess(state, action) {
      state.listPointOfInviteFriends = action.payload;
    },
    getToastMessSuccess(state, action) {
      state.toastMessage = action.payload;
    },
    getDailyMessSuccess(state, action) {
      state.dailyMessage = action.payload;
    },
    getMysteryTaskSuccess(state, action) {
      state.myterytask = action.payload;
    },
    getListPackageEnergySuccess(state, action) {
      state.listPackageEnergy = action.payload;
    },
    getTimeNextRefillSuccess(state, action) {
      state.timeNextRefill = action.payload;
    },
    setDailyCheckin(state, action) {
      state.dailyCheckin = action.payload;
    }
  },
});

export function getDataPoint(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(pointSlice.actions.getpoints(data));
    } catch (error) {
      console.log(error);
    }
  };
}
export function setDataExp(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(pointSlice.actions.setExps(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDataEnergy(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(pointSlice.actions.getEnergy(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDataMaxPoint(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(pointSlice.actions.getMaxpointSuccess(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getToastMess(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(pointSlice.actions.getToastMessSuccess(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDailyMess(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(pointSlice.actions.getDailyMessSuccess(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDataListTask() {
  return async (dispatch: Dispatch) => {
    try {
      const res = await KIBBLE_API.getListTask();
      dispatch(pointSlice.actions.getListTaskSuccess(res));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getCheckinInfo() {
  return async (dispatch: Dispatch) => {
    try {
      const res = await KIBBLE_API.dailyCheckin();
      dispatch(pointSlice.actions.setDailyCheckin(res?.data?.dailyCheckin));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDataListFriends(params?: any) {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await KIBBLE_API.getListFriends(params);
      dispatch(
        pointSlice.actions.getListFriendsSuccess({
          ...data,
          isLoadMore: params !== undefined,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDataListAsset(address: string) {
  return async (dispatch: Dispatch) => {
    try {
      if (!address) return;
      const listTokenInWallet = await getTokenInWallet(address);
      dispatch(pointSlice.actions.getListAssetSuccess(listTokenInWallet));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getListPointOfInviteFriends() {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await KIBBLE_API.getListPointOfInviteFriends();
      console.log("data" , data);
      dispatch(
        pointSlice.actions.getListPointOfInviteFriendsSuccess(data.pointInvite)
      );
    } catch (error) {
      console.log(error);
    }
  };
}

export function getInfoLevelUser(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(pointSlice.actions.getUserLevelSuccess(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getInfo() {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await KIBBLE_API.getListPointOfInviteFriends();
      const convertObjToArt: any = Object.values(data).reverse();
      dispatch(
        pointSlice.actions.getListPointOfInviteFriendsSuccess(convertObjToArt)
      );
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDataLeaderBoard(params?: any) {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await KIBBLE_API.getLeaderBoard(params);
      dispatch(
        pointSlice.actions.getLeaderBoadrdSuccess({
          ...data,
          isLoadMore: params !== undefined,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
}

export function getListPackageEnergy() {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await KIBBLE_API.getListPackageEnergy();
      dispatch(pointSlice.actions.getListPackageEnergySuccess(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getTimeNextRefill() {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await KIBBLE_API.getTimeNextRefill();
      dispatch(pointSlice.actions.getTimeNextRefillSuccess(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDataInfoUser(user: any) {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await KIBBLE_API.getInfoUser();
      // const dataForSettingLevel: any = getValueSetting(data.level);
      dispatch(
        pointSlice.actions.getInfoUserSuccess({
          ...data,
          ...user,
          // id: user.id,
          // currentPointNextLevel: dataForSettingLevel.pointOfLevel,
          // pointToPlus: dataForSettingLevel.pointToPlus,
        })
      );
      // dispatch(
      //   pointSlice.actions.getUserLevelSuccess({
      //     pointNextLevel: dataForSettingLevel.pointOfLevel,
      //     pointToPlus: dataForSettingLevel.pointToPlus,
      //     lv: data.level,
      //   })
      // );
      dispatch(pointSlice.actions.getpoints(data.points));
    } catch (error) {
      console.log(error);
    }
  };
}

const pointReducer = pointSlice.reducer;

export default pointReducer;
