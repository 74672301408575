import React, { useEffect, useState } from "react";
import {
  BocVal,
  BoxAvatar,
  BoxLeaderboard,
  BoxLeaderboardElm,
  BoxLeaderboardFloor,
  BoxLevel,
  BoxLv,
  BoxPosition,
  BoxPositionDes,
  BoxPositionDesVariable,
  BoxPositionIpoint,
  BoxPositionTT,
  BoxProgess,
  BoxProgessVal,
} from "./styled";
import icnClose from "../../assets/Game/button_close.png";
import imageBottom from "../../assets/Game/img_leaderboard_bottom.png";
import icnTop1 from "../../assets/Game/img_top_01.png";
import icnTop2 from "../../assets/Game/img_top_02.png";
import icnTop3 from "../../assets/Game/img_top_03.png";
import icnTop4 from "../../assets/Game/img_top_04.png";
import icnTop5 from "../../assets/Game/img_top_05.png";
import { GameContainer } from "../Earn/styledv2";
import { FloorFooter, FloorHeader } from "../../Layout/Dashboard/styled";
import {
  BoxCoin,
  BoxUserFloor,
  BoxUserHeader,
  BoxUserName,
  Progess,
} from "../../components/Header/styledv2";
import { useSelector } from "react-redux";
import { useDispatch } from "../../store/store";
import {
  getDataInfoUser,
  getDataLeaderBoard,
} from "../../redux/features/pointSlice";
import { useInitData } from "@tma.js/sdk-react";
import { RULE_GAME } from "../../constant";
import { convertFormatNumber } from "../../utils/convertFormatNumber";
const Leaderboard = () => {
  const dataUserTele: any = useInitData();
  const {
    initData: { user },
  } = dataUserTele;
  const dispatch = useDispatch();

  const { leaderBoard, infoUser } = useSelector((state: any) => state.point);
  useEffect(() => {
    const handleRefestApi = async () => {
      await dispatch(getDataLeaderBoard());
    };
    handleRefestApi();
  }, []);

  useEffect(() => {
    const handleRefestApi = async () => {
      await dispatch(getDataInfoUser(user));
    };
    handleRefestApi();
  }, []);
  const handleLoadLvprogess = (exp: any, level: any) => {
    switch (level) {
      case 0:
        return (exp / RULE_GAME.EXP.lv1) * 100;
        break;
      case 1:
        return (exp / RULE_GAME.EXP.lv2) * 100;
        break;
      case 2:
        return (exp / RULE_GAME.EXP.lv3) * 100;
        break;
      case 3:
        return (exp / RULE_GAME.EXP.lv4) * 100;
        break;
      case 4:
        return (exp / RULE_GAME.EXP.lv5) * 100;
        break;
      case 5:
        return (exp / RULE_GAME.EXP.lv6) * 100;
        break;
      case 6:
        return (exp / RULE_GAME.EXP.lv7) * 100;
        break;
      case 7:
        return 100;
        break;
    }
  };
  return (
    <GameContainer>
      <FloorHeader />
      <BoxPosition>
        <BoxPositionTT>
          <h1>My Position</h1>
        </BoxPositionTT>
        <BoxPositionDes>
          <BoxPositionDesVariable>
            <BoxUserHeader>
              <BoxUserFloor>
                <img
                  src={infoUser?.avatar_url || "./static/DefautAvt.png"}
                  onError={(e: any) => {
                    e.currentTarget.src = "./static/DefautAvt.png";
                  }}
                />
                <BoxUserName>
                  <p>{infoUser?.fullname}</p>
                  <Progess>
                    <h6>{infoUser?.level}</h6>
                    <BoxProgessVal
                      val={handleLoadLvprogess(infoUser?.exp, infoUser?.level)}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </BoxProgessVal>
                  </Progess>
                </BoxUserName>
              </BoxUserFloor>
            </BoxUserHeader>
            <BoxPositionIpoint>
              <BoxCoin>
                <img src="./static/ItonLogo.png" />
                <div>
                  <p>{infoUser?.points && convertFormatNumber(infoUser?.points)}</p>
                </div>
              </BoxCoin>
              <BoxCoin>
                <img src="./static/StarBlue.png" />
                <div>
                  <p style={{ textAlign: "center" }}>{infoUser?.rank && convertFormatNumber(infoUser?.rank)}</p>
                </div>
              </BoxCoin>
            </BoxPositionIpoint>
          </BoxPositionDesVariable>
        </BoxPositionDes>
      </BoxPosition>
      <BoxLeaderboard>
        <BoxLeaderboardFloor>
          {leaderBoard?.data?.map((elm: any, index: number) => {
            return (
              <BoxLeaderboardElm>
                <BoxAvatar rank={index + 1}>
                  <div>
                    <p>{index + 1}</p>
                  </div>
                  <div>
                    <img
                      src={elm?.avatar_url || "./static/avtDefaut.png"}
                      onError={(e: any) => {
                        e.currentTarget.src = "./static/avtDefaut.png";
                      }}
                    />
                  </div>
                </BoxAvatar>
                <BoxLevel>
                  <h6>{elm?.fullname}</h6>
                  <BoxLv>
                    <BoxProgess>
                      <p>{elm?.level}</p>
                    </BoxProgess>
                    <BoxProgessVal
                      val={handleLoadLvprogess(elm?.total_exp, elm?.level)}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </BoxProgessVal>
                  </BoxLv>
                </BoxLevel>
                <BocVal>
                  <img src="./static/StarBlue.png" />
                  <div>
                    <p>{elm?.total_exp}</p>
                  </div>
                </BocVal>
              </BoxLeaderboardElm>
            );
          })}
        </BoxLeaderboardFloor>
      </BoxLeaderboard>
      <FloorFooter />
    </GameContainer>
  );
};

export default Leaderboard;
