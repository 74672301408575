import styled from "styled-components";
import nav_bg from "../../assets/Game/nav_bg.jpg";

export const GameFooterWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 100000;
  aspect-ratio: 430/110;
  background-image: url("./static/NavBg.png");
  background-size: cover;
  background-position: center;
  bottom: 0;
  left: 0;
`;
export const SvgBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1;
  position: relative;
  .fill_svg {
    fill: #3a3869;
  }
  .stroke_svg {
    stroke: #4b5a9e;
  }
  g {
    filter: none;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    mix-blend-mode: luminosity;
  }
`;

export const NavFloor = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px auto;
  li {
    list-style: none;
  }
  .active {
    ${SvgBox} {
      g {
        filter: url("#filter0_d_193_236");
      }
      .fill_svg {
        fill: #44ecf5;
      }
      .stroke_svg {
        stroke: #1b7bd6;
      }
      img {
        mix-blend-mode: normal;
      }
    }
    p {
      color: #fff;
    }
  }
  p {
    color: #41529c;
    text-align: center;
    font-family: Poppins;
    font-size: 3.2vw;
    font-style: normal;
    font-weight: 800;
    line-height: 20px; /* 142.857% */
  }
  @media only screen and (min-width: 430px) {
    p {
      font-size: 14px;
    }
  }
`;
export const NavBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 19%;
  gap: 5px;
  position: relative;
`;
