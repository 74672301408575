import { AppContainer } from "./styled";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import "./App.css";
import { Navigate, Route, Router, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useContext, useEffect, useMemo, useState } from "react";
import LayoutDashboard from "./Layout/Dashboard";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import WebApp from "@twa-dev/sdk";
import { ContextProvider } from "./components/Context";
import {
  createNavigator,
  useNavigatorIntegration,
} from "@tma.js/react-router-integration";
import "animate.css";

import AssetsNew from "./page/AssetsNew";
import EarnV2 from "./page/Earn/indexv2";
import eruda from "eruda";
import LoadingV2 from "./page/LoadingV2";
import IntroGame from "./page/Intro";
import Game from "./page/Clicker";
import Friends from "./page/Friends";
import Leaderboard from "./page/Leaderboard";
import { useInitData } from "@tma.js/sdk-react";
// eruda.init();
declare global {
  interface Window {
    TelegramGameProxy?: any;
  }
}
const App = () => {
  const tmaNavigator = useMemo(createNavigator, []);
  const [location, navigator] = useNavigatorIntegration(tmaNavigator);
  const dataUserTele: any = useInitData();
  const [isLogin, setIsLogin] = useState(false);
  const {
    initData: { user },
  } = dataUserTele;

  const settingDefaultMiniApp = () => {
    WebApp.isClosingConfirmationEnabled = true;
    WebApp.expand();
    WebApp.ready();
    console.log("window.TelegramGameProxy");
  };

  useEffect(() => {
    settingDefaultMiniApp();
  }, []);

  useEffect(() => {
    const handleTouchMove = (e: any) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);
  useEffect(() => {
    settingDefaultMiniApp();
    document.body.style.overflowY = "hidden";
    window.scrollTo(0, 0);
  }, []);
  if (WebApp.platform === "tdesktop") {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: "url('./static/QrBot.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display:"flex",
          justifyContent: "center",
          paddingBottom: 50,
          alignItems:"flex-end"
        }}
      >
        <p style={{color:"#fff" , fontSize: 24 , fontWeight: 700}} >Play on your phone, please!</p>
      </div>
    );
  } else {
    return (
      <Router location={location} navigator={navigator}>
        <ContextProvider>
          <Provider store={store}>
            <TonConnectUIProvider manifestUrl="https://game.iton.co/static/iton-game.json">
              <Toaster position="top-right" reverseOrder={false} />
              <AppContainer>
                <Routes>
                  <Route
                    path="/loading"
                    element={
                      <LoadingV2 isLogin={isLogin} setIsLogin={setIsLogin} />
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <LoadingV2 isLogin={isLogin} setIsLogin={setIsLogin} />
                    }
                  />
                  <Route element={<LayoutDashboard />}>
                    <Route path="game" element={<Game />} />
                    <Route path="assets" element={<AssetsNew />} />
                    <Route path="earn" element={<EarnV2 />} />
                    <Route path="friends" element={<Friends />} />
                    <Route path="leaderboard" element={<Leaderboard />} />
                  </Route>
                  <Route path="*" element={<Navigate to="/loading" />} />
                </Routes>
              </AppContainer>
            </TonConnectUIProvider>
          </Provider>
        </ContextProvider>
      </Router>
    );
  }
};

export default App;
