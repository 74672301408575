import { AnyAaaaRecord } from "dns";
import { styled } from "styled-components";

export const GameContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  display: flex;
  /* aspect-ratio: 431/840; */
  margin: 0px auto;
  z-index: 1;
  &::after {
    width: 100vw;
    height: 100vh;
    content: "";
    background-image: url("./static/layoutBg.png");
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-position: center bottom;
    background-size: cover;
    z-index: -1;
  }
`;

export const BoxDaily = styled.div`
  width: 92.3%;
  margin: 0px auto;
  padding-top: 3.95vw;
  margin-bottom: 2.32vw;
  h3 {
    color: #fff;
    text-align: center;
    text-shadow: 0px 3px 0px #000;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
    font-family: Poppins;
    font-size: 4.65vw;
    font-style: normal;
    font-weight: 900;
    line-height: 100%; /* 20px */
    width: 100%;
    margin-bottom: 2.32vw;
  }
  @media only screen and (min-width: 430px) {
    padding-top: 17px;
    margin-bottom: 10px;
    h3 {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
`;
export const DailyList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  justify-content: space-around;
  &::after {
    width: 100%;
    height: 80%;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    border-radius: 5px;
    background: rgba(81, 93, 140, 0.52);
    backdrop-filter: blur(2px);
    position: absolute;
    z-index: -1;
  }
`;
export const BoxDailyElm = styled.div<{ mode?: any }>`
  width: 12.5%;
  aspect-ratio: 50/47;
  border: 1.5px solid #000;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.64);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2f3e5d;
  position: relative;
  margin-bottom: 3.26vw;
  p {
    color: #fff;
    font-family: Poppins;
    font-size: 5.58vw;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 24px */
  }
  ${({ mode }) =>
    mode === "next"
      ? `
  background: #444;
  p{
    color: #595959;
  }
  `
      : mode === "active" &&
        `
  background: #FCBA28;
  &:after{
    background-image: url("./static/activeArrow.png");
    content: "";
    width: 28%;
    aspect-ratio:1;
    position: absolute;
    bottom:2px;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    background-size: cover;
    max-width: 14px;
  }
  p{
    color: #000;
  }
  `}
  @media only screen and (min-width: 430px) {
    margin-bottom: 14px;
    p {
      font-size: 24px;
    }
  }
`;

export const BoxDailyVal = styled.div<{ mode?: any }>`
  border-radius: 9px;
  background: rgba(21, 47, 82, 0.58);
  display: inline-flex;
  padding: 1.86vw 1.86vw 1.86vw 3.72vw;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  @media only screen and (min-width: 430px) {
    padding: 8px 8px 8px 16px;
  }
`;
export const BtnClaim = styled.button<{isclaim?:any}>`
  border-radius: 6px;
  background: ${({isclaim})=> isclaim ? "#ffb825":"#bababa"} ;
  padding: 1.86vw 5.2vw 3.72vw;
  color: #fff;
  text-align: center;
  text-shadow: 0px 3px 0px #000;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #000;
  font-family: Poppins;
  font-size: 4.65vw;
  font-style: normal;
  font-weight: 900;
  line-height: 100%; /* 20px */
  border: none;
  position: relative;
  overflow: hidden;
  &:after {
    width: 100%;
    height: 1.86vw;
    max-height: 8px;
    content: "";
    background:${({isclaim})=> isclaim ? "#ba4e0a":"#444444"} ;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
  &:active {
    transform: translateY(6%);
  }
  @media only screen and (min-width: 430px) {
    padding: 9px 22px 16px;
    font-size: 20px;
  }
`;
export const BoxVal = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  img {
    width: 7.44vw;
    max-width: 32px;
    aspect-ratio: 1;
  }
  p {
    color: #fff;
    font-family: Poppins;
    font-size: 4.65vw;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 100% */
  }
  @media only screen and (min-width: 430px) {
    p {
      font-size: 20px;
    }
  }
`;
export const BoxListTask = styled.div`
  width: 100%;
  overflow-y: auto;
  height: calc(100% - 193.5px - 21.62vw - 25.58vw);
  @media only screen and (max-width: 430px) {
    height: calc(100% - 45vw);
  }
`;
export const BoxListTaskFloor = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const ListTask = styled.div<{ isClaim?: boolean }>`
  width: 92.3%;
  margin: 0px auto;
  aspect-ratio: 395/ 81;
  background-image: url(${({ isClaim }) =>
    isClaim ? "./static/TaskListBg2.png" : "./static/TaskListBg.png"});
  border-radius: 6px;
  background-size: cover;
  background-position: top right;
  overflow: hidden;
  position: relative;
  display: flex;
  box-sizing: border-box;
  &::after {
    width: 100%;
    aspect-ratio: 395/27.35;
    position: absolute;
    top: 0px;
    right: 0px;
    background-image: url(${({ isClaim }) =>
      isClaim ? "./static/TaskLBg2.png" : "./static/TaskLBg.png"});
    background-size: cover;
    background-position: top right;
    z-index: 1;
    content: "";
  }
`;
export const TaskLogo = styled.div<{ isClaim?: boolean }>`
  width: 23.6%;
  height: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  &::after {
    width: 100%;
    aspect-ratio: 93.35/27.35;
    position: absolute;
    top: 0px;
    right: 0px;
    background-image: url(${({ isClaim }) => isClaim ? "./static/TaskLgBg2.png" : "./static/TaskLgBg.png"});
    background-size: cover;
    background-position: top right;
    z-index: -1;
    content: "";
  }
`;
export const BoxImgLogo = styled.div<{bg?:any}>`
  width: 69.89%;
  aspect-ratio: 1;
  border-radius: 4px;
  background-image: url(${({ bg }) => bg });
  background-size: cover;
  background-position: center;
  box-shadow: -2px 3px 0px 0px rgba(0, 0, 0, 0.25);
  position: relative;
`;
export const BoxImgLogoVal = styled.div<{ isClaim?: boolean }>`
  width: 110%;
  background-image: url(${({ isClaim }) =>
    isClaim ? "./static/BgValTaskL2.png" : "./static/BgValTaskL.png"});
  background-size: cover;
  background-position: top left;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(30%);
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 108/30;
  gap: 5px;
  img {
    height: 80%;
  }
  p {
    color: #fff;
    font-family: Poppins;
    font-size: 2.55vw;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 11px */
  }
  @media only screen and (min-width: 430px) {
    p {
      font-size: 11px;
    }
  }
`;
export const TaskDetail = styled.div`
  width: 76.4%;
  height: 100%;
  position: relative;
  z-index: 2;
`;
export const TaskDetailTT = styled.div<{ isClaim?: boolean }>`
  width: 100%;
  aspect-ratio: 302/27.35;
  display: flex;
  align-items: center;
  h1 {
    color: ${({ isClaim }) => (isClaim ? "#000" : "#fff")};
    font-family: Poppins;
    font-size: 3.25vw;
    font-style: normal;
    font-weight: 700;
    line-height: 142.857%;
    letter-spacing: -0.5px;
  }
  @media only screen and (min-width: 430px) {
    h1 {
      font-size: 14px;
    }
  }
`;
export const ContentBox = styled.div<{ isClaim?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  gap: 1.86vw;
  padding: 1.86vw;
  p {
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 2.8vw;
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%; /*  */
    letter-spacing: -0.5px;
    display: -webkit-box;
    max-width: 75%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  button,
  a {
    border-radius: 0.93vw;
    width: fit-content;
    background: ${({ isClaim }) => (isClaim ? "#FFB825" : "#109ee6")};
    padding: 1.86vw 2.8vw;
    color: #fff;
    text-align: center;
    text-shadow: 0px 2.224px 0px #000;
    font-family: Poppins;
    font-size: 3.3vw;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    min-height: 6vw;
    border: none;
    outline: none;
    -webkit-text-stroke-width: 0.7412850260734558;
    -webkit-text-stroke-color: #000;
    &::after {
      content: "";
      width: 100%;
      height: 0.93vw;
      position: absolute;
      background: ${({ isClaim }) => (isClaim ? "#BA4E0A" : "#153E89")};
      left: 0;
      bottom: 0;
      max-height: 8px;
    }
    .loader {
      width: 80%;
      min-width: 45px;
      min-height: 7px;
      height: 50%;
      --c: #fff 92%, #0000;
      background: radial-gradient(circle closest-side, var(--c)) calc(100% / -4)
          0,
        radial-gradient(circle closest-side, var(--c)) calc(100% / 4) 0;
      background-size: calc(100% / 2) 100%;
      animation: l14 1.5s infinite;
    }
    @keyframes l14 {
      0% {
        background-position: calc(100% / -4) 0, calc(100% / 4) 0;
      }
      50% {
        background-position: calc(100% / -4) -14px, calc(100% / 4) 14px;
      }
      100% {
        background-position: calc(100% / 4) -14px, calc(3 * 100% / 4) 14px;
      }
    }
  }
  @media only screen and (min-width: 430px) {
    gap: 8px;
    padding: 8px;
    min-height: 28px;
    p {
      font-size: 12px;
    }
    button {
      padding: 8px 12px;
      font-size: 15px;
    }
  }
`;
